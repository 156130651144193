var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "leftColumn" },
    [
      _c(
        "graphTitle",
        { attrs: { title: "会员支付占比" } },
        [
          _c(
            "el-tooltip",
            {
              attrs: {
                placement: "top",
                content:
                  "展示统计时间内，会员支付金额与非会员支付金额的对比。会员支付订单数与非会员支付订单数的对比。按支付时间统计。",
              },
            },
            [_c("i", { staticClass: "el-icon-question my-icon" })]
          ),
        ],
        1
      ),
      _c("div", { style: { height: "260px" }, attrs: { id: "piea" } }),
      this.number == 0
        ? _c(
            "p",
            {
              staticStyle: {
                "text-align": "center",
                color: "rgba(0, 0, 0, 0.65)",
                "font-size": "17px",
              },
            },
            [
              _vm._v(
                " 收入总额：" +
                  _vm._s(
                    _vm._f("toThousands")(
                      (
                        (Number(_vm.pieData.memberPay) +
                          Number(_vm.pieData.nonmemberPay)) /
                        100
                      ).toFixed(2)
                    )
                  ) +
                  "元 "
              ),
            ]
          )
        : _vm._e(),
      this.number == 1
        ? _c(
            "p",
            {
              staticStyle: {
                "text-align": "center",
                color: "rgba(0, 0, 0, 0.65)",
                "font-size": "17px",
              },
            },
            [
              _vm._v(
                " 实收订单：" +
                  _vm._s(
                    Number(_vm.pieData.memberPayCount) +
                      Number(_vm.pieData.nonmemberPayCount) || 0
                  ) +
                  "个 "
              ),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "switch" }, [
        _c(
          "p",
          {
            class: _vm.number == 0 ? "add-style" : "default-style",
            staticStyle: { cursor: "pointer" },
            on: { click: _vm.gold },
          },
          [_vm._v(" 收入金额 ")]
        ),
        _c(
          "p",
          {
            class: _vm.number == 0 ? "default-style1" : "add-style1",
            staticStyle: { cursor: "pointer" },
            on: { click: _vm.pen },
          },
          [_vm._v(" 订单数量 ")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }